/* Global body styling */
body {
  background-color: #ffffe1;
}

/* Navbar styling */
.navbar {
  background-color: #ffffe1;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 0 24px;
}

/* Navbar container */
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
}

/* Navbar menu */
.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  gap: 1rem;
  margin-right: 2rem;
  padding-bottom: 25px;
}

/* Navbar logo */
.navbar-logo {
  color: #27241e;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

/* Link styling */
.rubik-nav-link-style {
  font-family: "Rubik", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
}

.nav-links {
  color: #27241e;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: "Rubik", serif;
  font-weight: 500;
  font-style: italic;
  position: relative;
}

/* Link underline effect */
.nav-links::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0px;
  width: 0;
  height: 4px;
  background-color: #27241e;
  border-radius: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease;
}

.nav-links:hover::after {
  width: 20px;
}

/* Menu icon */
.menu-icon {
  display: none;
}

/* Responsive adjustments for mobile screens */
@media screen and (max-width: 960px) {
  .nav-menu {
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    text-align: center;
    padding: 0; /* Reset padding for proper alignment */
  }

  .nav-menu.active {
    background: #27241e;
    left: 0;
    opacity: 1;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 1.5rem 0; /* Adjust padding for better alignment */
    width: 100%;
    display: block; /* Ensure full width alignment */
  }

  .nav-menu.active .nav-links {
    color: #ffffe1;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
    color: #27241e;
  }
}