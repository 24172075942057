/* src/components/Events.css */

.events-container {
  max-width: 90%;
  margin: auto;
  padding: 20px;
  color: #27241e;
  padding-bottom: 150px;
}

.eventsh1{
    font-family: "Playwrite GB S", serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    color: #38301e;
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
 
}
.adh{
  font-family: "Rubik", serif;
  font-style: italic;
  font-size: 40px;
  color: #4c6444;
  border-bottom: 2px solid #1f2020;
}
.events-grid {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 20px;
  align-items: start;
}

.brochure {
  display: flex;
  justify-content: center;
}
 
.brochure-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.event-details {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  font-family: "Rubik", serif;
  font-size: 20px;
  border-bottom: 2px solid #1f2020;
  line-height: 1.6;
}

.event-details h2 {
  color: #27241e;
  font-size: 1.8em;
  margin-top: 30px;
  text-align: left;
}

.event-details p {
  font-size: 1.1em;
  color: #27241e;
}

.event-details ul {
  margin-left: 20px;
  padding-left: 15px;
  list-style-type: disc;
  color: #27241e;
}

.event-details ul li {
  margin: 10px 0;
  color: #27241e;
}

.event-details ul ul {
  list-style-type: circle;
  padding-left: 15px;
}

.highlight {
  background-color: #c7eb9e;
  padding: 15px;
  margin: 20px 0;
  border-left: 5px solid #1a1c1f;
  color: #27241e;
  border-radius: 8px;
  font-size: 1.1em;
}

.highlight p {
  margin: 5px 0;
}

.footer-note {
  font-size: 1.2em;
  margin-top: 30px;
  text-align: center;
  color: #38301e;
  font-style: normal;
  padding-top: 15px;
  border-top: 1px solid #38301e;
}

/* Button alignment */
.event-details .btn {
  display: block;
  margin: 20px auto 0;
  text-align: center;
  background-color: #4c6444;;
  color: white;
  border-radius: 15px;
}
/* Base Styles */
.events-container {
  padding: 20px;
}

.events-grid {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

/* Brochure Image Styles */
.brochure {
  flex: 1;
}

.brochure-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Event Details Styles */
.event-details {
  flex: 2;
}

/* Mobile Styles: Reorder Layout */
@media screen and (max-width: 768px) {
  .events-grid {
    flex-direction: column;
  }

  /* Move Brochure Above Event Details */
  .brochure {
    order: -1;
    margin-bottom: 20px;
  }
}
