/* Footer Container */
.footer-container {
  background-color: #393533;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Footer Links */
.footer-links {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 20px;
  padding-top: 50px;
}

.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  font-family: "Rubik", serif;
  font-weight: 500;
  font-size: 15px;
  font-style: italic;
}

.footer-column a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

/* Social Media Section */
.social-media {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 50px;
}

.social-icons a {
  color: #bbaba4;
  font-size: 24px;
  transition: 0.3s ease-out;
}

.social-icons a:hover {
  color: #e9e9e9;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    margin-bottom: 20px;
    align-items: center;
    
  }

  .social-media {
    flex-direction: column;
  }

  .social-icons {
    flex-direction: row;
    gap: 20px;
  }
}