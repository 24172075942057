/* General Styles */
.about-container {
  padding: 2rem;
  font-family: "Rubik", serif;
  color: #27241e;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  gap: 1.5rem;
  background-color: #f8f9fb;
}

/* Headings */
.servHeadings {
  font-family: "Playwrite GB S", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  color: #38301e;
  font-size: 3rem;
  margin: 20px 0;
  text-align: center;
}

/* Paragraph Styles */
.servP {
  padding-bottom: 20px;
  text-align: center;
  font-size: 20px;
}

/* About Grid */
.about-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

/* Card Styles */
.about-card {
  position: relative;
  width: 100%;
  height: 350px;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-card:hover {
  transform: scale(1.05);
}

/* Image Overlay */
.about-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
}

/* Details Container */
.details-container {
  padding: 2rem;
  background: linear-gradient(145deg, #e3e4e8, #cfd2d9);
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  text-align: center;
  border: 1px solid #e0e0e0;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-size: 20px;
}

.details-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #555;
  cursor: pointer;
  transition: color 0.2s ease;
}

.close-button:hover {
  color: #27241e;
}

/* Dot Indicators */
.dot-indicators {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 6px;
  background-color: #d0d0d0;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.dot.active {
  background-color:#27241e;
}

.dot:hover {
  background-color: #bbaba4;;
}

/* Mobile View */
@media (max-width: 768px) {
  .details-container {
    margin-top: 1rem;
    width: 90%;
    padding: 1.5rem;
  }
}

/* Desktop View */
@media (min-width: 769px) {
  .about-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .details-container {
    grid-column: 1 / -1;
    margin-top: 2rem;
  }
}

/* Icon Styles */
.fa-forward,
.fa-backward {
  text-decoration: none;
  font-size: 1.25rem;
  color:#bbaba4;;
  background: none;
  transition: color 0.2s ease;
}

.fa-forward:hover,
.fa-backward:hover {
  color:#bbaba4;;
}

/* Icon Styles for Nav Button */
.nav-button i {
  font-size: 1.25rem;
}

/* Arrow Button Styles */
.arrow-button {
  background: none;
  border: none;
  color: #bbaba4;
}
/* Icon Styles */
.arrow-button i {
  font-size: 1.5rem;
  color: #bbaba4;;
  transition: color 0.2s ease;
}

/* Hover Effect */
.arrow-button:hover i {
  color: #27241e;
}
